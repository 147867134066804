import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { Footer, Header } from "../../common";
import "./HomeStyle.scss";
import mainVideo from "../../../assets/videos/parrotWebIntro.mp4";
import TeamSlider from "./TeamSlider";
import "react-accessible-accordion/dist/fancy-example.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap, Power3 } from "gsap";
import Faq from "./Faq";
import videoPlaceholder from "../../../assets/video_coin_placeholder.jpg";
import eliteCoinVideo from '../../../assets/videos/elite_coin_video.mp4'
import parrotStormTeaser from "../../../assets/videos/parrotStormTeaser.mp4";
import ScrollContainer from 'react-indiana-drag-scroll'
import progressBar from '../../../assets/progressBar.svg'
import arrowForward from '../../../assets/arrow_slider.svg'
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from '../Loader/Loader'
import imgr from '../../../assets/coin-moving.gif'

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const [loading, setLoading] = useState(true);
  const el = useRef();
  const q = gsap.utils.selector(el);

  useEffect(() => {

    let stl = gsap.timeline({
      scrollTrigger: {
        trigger: q("#gsSection"),
        start: "top top", // position of trigger meets the scroller position
        end: "bottom bottom",
        pin:q('.videoBg'),
        scrub: 1,        
        // markers:true,
      },
    });

    stl.fromTo(q(".welcome"), {yPercent:0}, {yPercent:-120})
    
    // stl.fromTo(q(".wTextLast"), {xPercent:-100, yPercent:0}, {xPercent:0, yPercent:-100})

    // gsap.utils.toArray(
    //   q(".welcome>p").forEach((item)=>{
    //     stl.to(item, {
    //       yPercent: -100,
    //       duration: 0.5,
    //       ease: 'none',          
    //     });
    //   }
    //   )
    // )
    
    // let welTl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: q("#gsSection"),
    //     start: "top top", // position of trigger meets the scroller position
    //     end: "bottom bottom",
    //     pin:q('.wtextWrap'),
    //     scrub: 1,        
    //     // markers:true,
    //   },

    // });

    // welTl.fromTo(q('.wTextLast'),
    // {  xPercent:-100 }, 
    // {xPercent:0, duration: 5, immediateRender: false })


       

    let fqTl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: q("#secFAQ"),
        scrub: 1,
        start: "top center", // position of trigger meets the scroller position
        end: "bottom bottom",
        // markers:true
      },
    });


    gsap.utils.toArray(
      q("#faqList>div").forEach((item) => {
        fqTl.from(item, {
          y: 100,
          duration: 0.1,
          opacity: 1,
          // delay: 0.04,
          ease: Power3.easeIn,
          // transition: 0.25,
        });
      })
    );

        const videoHolder = document.querySelector('.video_wrap')
        const videMainWrap = document.querySelector('.home-video-mask');
        const videoPlaceholder =  document.querySelector('.videImg');
       
        videoHolder.addEventListener("mouseover", function(e){
            videMainWrap.style.display = "block"
            videoPlaceholder.style.opacity = "0"
        });

        videoHolder.addEventListener("mouseleave", function(e){
            videMainWrap.style.display = "none"
            videoPlaceholder.style.opacity = "1"
        });


        const rlist = document.querySelectorAll('.roadMapStyle li');
        // let roadItem = document.querySelectorAll('.roadMapItem');
        const arrowForward = document.querySelector('.arrow-drag');
        // const progWrap = document.querySelector('.progressBar');

        let rListData = [];
        const rListDataFn = function () {
        for (let i = 0; i < rlist.length; i++)
        
        rListData.push(rlist[i].clientWidth);
        
      };
      rListDataFn(); //
      // const sum = rListData.reduce((partialSum, a) => partialSum + a, 0);

      // console.log(sum)

      // const getHalfValue = rlist[0].clientWidth / 2;

      // console.log('get adhi value', getHalfValue);

      // progWrap.style.width = `${sum}px`;
 

      const makeActive = function () {
        for (var i = 0; i < rlist.length; i++)
            //elems[i].classList.remove('active');
        
        this.classList.add('active');
        // ritems.rItem.opacity = "1";
      };

      
      for (var i = 0; i < rlist.length; i++)
      rlist[i].addEventListener('mousedown', makeActive);

      rlist[0].addEventListener('mousedown', function(e){
        arrowForward.style.opacity = "0";
        arrowForward.style.transition = "opacity .5s"
      })

    // document.body.classList.add("Loading");
    setTimeout(() => {
      setLoading(false);                   
    }, 3000);

   

  }, [q]);

  useEffect(()=>{
    AOS.init({
      duration : 2000
    });
    AOS.refresh();
  }, [])
  
  return (
    <div ref={el}>
      {loading && <Loader />}
      <Header />

      <div className="wrapper_style2 homeSection" id="gsSection">
        <div className="videoBg">
          <video className="videoTag" autoPlay loop muted>
            <source src={mainVideo} type="video/mp4" />
          </video>
          
        </div>
        <div className="welcome">
          <p className="wText">Welcome to</p>
          <p className="wTextSocity">Elite Betting Society</p>           
        </div>
        <p className="wTextLast" 
            data-aos="fade-right"
            data-aos-offset="300"
           >Turning dreams into Empire</p>      
      </div>

      <div className="wrapper_style vWrap">
        <div className="container-inner v-container" >
          <h2 className="title-text" data-aos="fade-up">
            <strong>Evolve? Why ? Because we can!</strong>
          </h2>
          <div className="video_wrap" data-aos="fade-up">
            <span className="btn-playVideo"></span>
            <img className="videImg" alt="" src={videoPlaceholder} />
            <div className="home-video-mask" style={{ display: "none" }}>
              <video className="videos" loop muted autoPlay playsInline>
                <source src={eliteCoinVideo} type="video/mp4" />
                {/* <source src="https://uploads-ssl.webflow.com/61b6a83fcd4f622ea95c5f20/61b6a83fcd4f624ea85c5fca_Autoplay%20Video-transcode.webm" type="video/ogg" /> */}
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>


      <div className="wrapper_style aboutSec" id="secAbout">
        <div className="container-inner" id="gsAbout">
          <div className="row-inner">
            <div className="col-inner col-full" id="abContent">
              <h2 className='title-text' id="gsAboutTitle">
                  <strong data-aos="fade-up-left"
      data-aos-anchor-placement="top-center">About</strong>
                  <span  className='subTitle' data-aos="fade-up-right" data-aos-anchor-placement="top-center"
     >We are building the future of to-do's.</span>
              </h2>
              
              <h3 data-aos="fade-up" data-aos-anchor-placement="top-center" className='wrTitle gsAbtText'>
                Elite Betting Society is a revolutionary ecosystem designed to
                turn the dreams of gaming enthusiasts into a virtual empire.
                Unlike the corrupt conventional online casinos that reek of
                problems like
              </h3>
              <ul className='abtList gsAbtText'>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">Lax age verification mechanisms</li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">Non-existent money-back guarantees</li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">Unreliable and faulty game rules,</li>
              </ul>
              <h3 data-aos="fade-up" data-aos-anchor-placement="top-center" className='gsAbtText'>
                Elite Betting Society brings you absolute relief in the form of
              </h3>
              <ul className='abtList gsAbtText' >
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">Sheer transparency</li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">Impregnable security</li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">Decentralized network.</li>
              </ul>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center" className='gsAbtText'>
                In its essence, EBS is a comprehensive casino ecosystem built on the blockchain network by the real casino buffs for the real  casino buffs.
                <br />
                But that's not it, hell, no! That's the start of it. A fair system is not the end goal; it's the bare minimum
              </p>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center" className='gsAbtText'>
                What truly makes EBS one of a kind is its versatility, its fluidity. Your freedom is not ours or a sell-out company's to
                decide; it's your right. And that right is what you relish here. From how much it snows in god-knows-what Alaska today to how much Ronaldo scores in the match, from how poorly the stock exchange breaks today to how wonderfully The Batman performs. You decide what you want to bet your money on. It's not one of some silly system's three pre-judged, barely possible choices. It's your choice, your decision.
              </p>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center" className='gsAbtText'>
                Elite Betting Society opens the dreamland portal to an ingenious virtual casino. Here, you aren't a Saturday regular engaging with the same slot stick over and over again. Here, you are an ultra-privileged invitee with access to one of a kind, tremendously versatile, and hauntingly secure third-party free casino wagers. Beyond that, catering to that undying spirit of fun, you even get to own NFT collectables treasuring your accomplishments made here.
              </p>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center" className="text-heilighted gsAbtText" style={{ paddingLeft: 0 }}>
                Welcome to the future. Welcome to EBS
              </p>
            </div>
          </div>
        </div>
      </div>



      <div className="wrapper_style" id="secRoadmap">
    <div className="container-inner">
    <h2 className='title-text'>
          <strong data-aos="fade-up"
     data-aos-easing="ease-in-sine">Roadmap</strong>
          <span className='subTitle' data-aos="fade-up"
     data-aos-easing="ease-in-sine">Ohh, boy, you are in for a ride.</span>
      </h2>
     
      <p data-aos="fade-up"
     data-aos-easing="ease-in-sine">
      All right, ladies and gentlemen, put yourselves in your seats, tuck
        yourselves tight, for the expedition has begun. Consider what's
        mentioned ahead as individual dominos that shall fall part-by-part
        on to the following, putting in motion the most fantastic betting
        ecosystem this world has ever laid eyes upon. Ohh, boy, you are in
        for a ride.
      </p>
      </div>
      <div className="container-inner cfull">
      <div className="row-inner roadSectoin">
        <div className="col-inner col-full">
          <div className="roadMapVideoSection">
            <video className="video" loop muted autoPlay playsInline>
              <source src={parrotStormTeaser} type="video/mp4" />
              {/* <source src="https://uploads-ssl.webflow.com/81b6a83fcd4f622ea95c5f20/61b6a83fcd4f624ea85c5fca_Autoplay%20Video-transcode.webm" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
            <ScrollContainer activationDistance={10} className="scroll-container">
            <div className='roadMap'>
            <div className='progressBar'>
                <img alt="progressBar" src={progressBar} />
              </div>
            <ul id="roadMapList" className="roadMapStyle">
            <li data-aos="fade-up"
     data-aos-easing="ease-in-sine" className='rlist active'>
              <div   className='roadMapItem'>
                <h2>NFT Collection Launch</h2>
                <p>
                  EBS launches its exclusive collection of 4444 parrots NFTs,
                  each as astounding as it could be. Every NFT created here is
                  a collection of strokes made so intricately that you can't
                  help but submit to a visual-gasm.
                </p>
              </div>
            </li>
            <li className='rlist'>
              <div  className='roadMapItem'>
              <h2>Community Building</h2>
              <p>
                The core of every great institution is a solid community
                that spines it. And that is what we shall develop here.
                Giveaways, airdrops, collaborations, an endless list of
                activities will give our members their first gist of what it
                means to be a part of the EBS community. A society of risk
                takers and decadents who consider volatility to be their
                best pal.
              </p>
              </div>
            </li>
            <li className='rlist'>
              <div  className='roadMapItem'>
              <h2>Expansion</h2>
              <p>
                This card marks the advent of advantageous times- the time
                for whitelisting. In return for all your efforts- the
                participation, the interaction- EBS will offer you a spot on
                the whitelist. And well, well, well, you can only imagine
                the perks that wait for you there.
              </p>
              </div>
            </li>
            <li className='rlist'>
            <div  className='roadMapItem'>
              <h2>EBT Token Launch</h2>
              <p>
                Conjured with the most elaborate yet the most interesting of
                tokenomics, EBS presents to you the EBT Token. Every NFT
                owner will be airdropped these tokens, which they can then
                use for purchasing merchandise and services. Parallelly, a
                DAO would also be sculpted into existence. And 30% of all
                the profits shall be put into the EBT DAO vault, NFT owners
                would be a member of which.
              </p>
              <p>
                <strong>Sale:</strong> The pre-sale and public-sale kickstarting the ride.
              </p>
              </div>
            </li>
            <li className='rlist'>
              <div  className='roadMapItem'>
              <h2>Land over Decentraland</h2>
              <p>
                Elite Betting Society will etch their name, thereby yours,
                in the timeless land of meta by buying a rather chunky piece
                of land in Decentraland. This will be the base for our
                like-no-other betting-hub cum casino.
              </p>
              </div>
            </li>
            <li className='rlist'>
              <div  className='roadMapItem'>
                <h2>The Take-off</h2>
                <p>
                  The inexorable is only a matter of time, literally. That's
                  what we witness here. EBS has the metaverse turned on its
                  head. The betting portal is out of bounds, the ecosystem
                  platform can't seem to catch a break, and our members can't
                  stop this feeling of joy.
                </p>
              </div>
            </li>
            <li className='rlist'>
              <div  className='roadMapItem'>
                <h2>The Betting Ecosystem</h2>
                <p>
                  Here you come. Entering into a place you know will change
                  your lives forever- our exclusive virtual casino finally
                  stands in Dectranland. And within it rests a P2E game ruled
                  by parrots, calling for you, by your name.
                </p>
              </div>
            </li>
          </ul>
          </div>
          </ScrollContainer>
          <div className='arrow-drag'>
              <img src={arrowForward} alt="" />
          </div>
          </div>
          
        </div>        
      </div>
    </div>
  </div>


  <div className="wrapper_style" id="secStory" >
        <div className="container-inner storySection" id="stId">
        <h2 className='title-text' id="stTitle">
          <strong data-aos="fade-up" data-aos-anchor-placement="top-center"> Story</strong>
          <span className='subTitle' data-aos="fade-up" data-aos-anchor-placement="top-center">"There is no nobility in poverty."</span>
        </h2>
          <div className="row-inner">
            <div className="col-inner col-left story_coin">
              <img src={imgr} alt="coin" />           
            </div>
            <div className="col-inner col-right" id="paraId">
              <p data-aos="fade-up" data-aos-anchor-placement="top-center">
                Ever since the dawn of time, the Hawks have tried to manipulate
                us, the Parrots, into cutting our own wings. Spewing horror like
                sleeping in the nest would enrich our souls, or that living a
                humble life would reflect kindly on the judgment day. Well, let
                me tell you something, we're Parrots, and we don't give two
                bullocks about it. We don't waste our lives listening to their
                silly conspiracy theories or crazy communist agendas. No, we go
                out there in the open sky. We spread our wings, and we fly like
                we own the place. And guess what, we flipping do.
              </p>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center">
                It's either win, or it's loose. It always has been that way. And
                yeah, no doubt, winning means risking to lose. Yes, it's always
                a gamble. Blah blah blah. But would you instead be checking your
                bank balance twice before ordering pizza. Going to the grocery
                stores on Thursdays looking for cheap onions in bulk? Raising
                fundraisers to afford your granny's treatment? Taking loans for
                funding your college. Eh?
              </p>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center">
                Or would you be brave enough to pull the lever, run the slot
                machine, trust in your judgment, and own your goddamn life? If
                crying is what you must do, would you rather do it in the back
                of a second-hand pinto or in the driving seat of a bloody
                McLaren? Tell me?
              </p>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center">
                Are you not sick of it already? Does your blood not boil? How
                much longer do you intend to be enslaved? Level up, now. Rise
                above, now. Enough with the Hawks. Be a goddamn Parrot. Smoke
                exotic cigars on Sundays, and drink fine whisky for brunch. And
                do it simply because you freaking want to. It's your bloody
                right. Come and take it now.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className="wrapper_style" id="perGsA">
        <div className="container-inner">
        <h2 className='title-text' id="perGsB">
          <strong data-aos="fade-up"
     data-aos-easing="ease-in-sine"> Perks</strong>
          <span data-aos="fade-up" data-aos-offset="150"
     data-aos-easing="ease-in-sine" className='subTitle' id="perGsC">Perks of Holding Elite Betting Society's  NFTS</span>  
        </h2>
                    
          <div className="row-inner">
           
            <div className="col-inner col-right perk_right">
              <h3 data-aos="fade-up">
                As a holder of an NFT from Elite Betting Society, you possess
                not only irrefutable ownership of a singularly-made unique art
                piece but also the following benefits:
              </h3>
              <ul>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">
                  Your NFT is a free ticket into our metaverse, where your
                  presence alone exacts a passive income.{" "}
                </li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">You are eligible to receive EBS token airdrop.</li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">
                  You get to vote on the Elite Betting Society vault, containing
                  30% of all residual profits.
                </li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">
                  You may be the one EBS selects randomly every week to share
                  mighty 25% of all weekly profits.
                </li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">
                  You may also partake in NFT staking, lending, and/or borrowing
                  as per your will.
                </li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">
                  Existing NFT holders and immediate former NFT holders get the
                  privilege of participating in custom betting programs and
                  exclusive popularity betting for enormous earnings.
                </li>
                <li data-aos="fade-up" data-aos-anchor-placement="top-center">
                  And the jackpot- you will be one of the selected few who
                  receive royalty rewards from the overall revenue generated if
                  you possess a legendary NFT or an HML's choice NFT.
                </li>
              </ul>
              <p data-aos="fade-up" data-aos-anchor-placement="top-center" className="text-heilighted">
                If that weren't enough in itself, even more, productive perks
                would be announced at launch.
              </p>
            </div>
            <div className="col-inner col-left cards_list_wrap">
                <ul className="cards_list">
                  <li data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"></li>
                  <li data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"></li>
                  <li data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"></li>
                  <li data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"></li>
                  <li data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"></li>
                  <li data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"></li>
                </ul>
            </div>
          </div>
        </div>
      </div>
      
      <TeamSlider
        teamTitle="teamTitleID"
        teamSectionId="secOurTeam"
        teamSlider="teamSliderID"
      />

      <Faq faqBg="faqBg" secFaqID="secFAQ" faqsList="faqList" />
      <Footer />
    </div>
  );
};

export default Home;
