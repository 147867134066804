import React from 'react'
import './LoaderStyle.scss'
import imgr from '../../../assets/coin-moving.gif'

const Loader = () => {
   
  return (
    <div className="container_loader">
        <img  src={imgr} alt="Loader" />
    </div>
  )
}

export default Loader