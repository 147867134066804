import React from 'react'
import './FooterStyle.scss'

const Footer = () => {
  return (
    <div className='container-full footer_style'>
      <div className='container-inner'>
        &#169; {new Date().getFullYear()} Elite Betting 
        </div>
      </div>
  )
}

export {Footer}