import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Home } from './component/pages';
import Router from './component/Router/Router';

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
 
  );
}

export default App;
