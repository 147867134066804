import React from "react";
import Slider from "react-slick";
import "./TeanSliderStyle.scss";
import slide1 from "../../../assets/slide_1.png";
import slide2 from "../../../assets/slide_2.png";
import slide3 from "../../../assets/slide_3.png";
import slide4 from "../../../assets/slide_4.png";
import tw from "../../../assets/social/icon_twitter.png";

let settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  centerMode: true,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  centerPadding: "0px",
  dots: false,
  responsive: [
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TeamSlider = (props) => {
 
  return (
    <div className="wrapper_style" id={props.teamSectionId}>
      <h2 data-aos="fade-up" data-aos-offset="100" className="title-text">
        <strong id={props.teamTitle}>Team</strong>
      </h2>
      <div data-aos="fade-up" data-aos-offset="100" id={props.teamSlider} className="container-inner">
        <Slider className="slider_style" {...settings}>
          <div className="slider_item">
            <div>
              <div className={`s_img ${props.smClass}`}>
                <img alt="" src={slide1} />
              </div>
              <div className="s_desc">
                <h3>
                  Golden Bayc <br />
                  Lorem Ipsum
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <h2>Development Manager</h2>
                <h6>
                  <a className="iconTwitter" href="javascriptt:void(0)">
                    <img src={tw} alt="twitter" />
                  </a>{" "}
                  Goldenbayc
                </h6>
              </div>
            </div>
          </div>
          <div className="slider_item">
            <div>
              <div className="s_img">
                <img alt="" src={slide2} />
              </div>
              <div className="s_desc">
                <h3>
                  Golden Bayc <br />
                  Lorem Ipsum
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <h2>Development Manager</h2>
                <h6>
                  <a className="iconTwitter" href="javascriptt:void(0)">
                    <img src={tw} alt="twitter" />
                  </a>
                  Goldenbayc
                </h6>
              </div>
            </div>
          </div>
          <div className="slider_item">
            <div>
              <div className="s_img">
                <img alt="" src={slide3} />
              </div>
              <div className="s_desc">
                <h3>
                  Golden Bayc <br />
                  Lorem Ipsum
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <h2>Development Manager</h2>
                <h6>
                  <a className="iconTwitter" href="javascriptt:void(0)">
                    <img src={tw} alt="twitter" />
                  </a>
                  Goldenbayc
                </h6>
              </div>
            </div>
          </div>
          <div className="slider_item">
            <div>
              <div className="s_img">
                <img alt="" src={slide4} />
              </div>
              <div className="s_desc">
                <h3>
                  Golden Bayc <br />
                  Lorem Ipsum
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <h2>Development Manager</h2>
                <h6>
                  <a className="iconTwitter" href="javascriptt:void(0)">
                    <img src={tw} alt="twitter" />
                  </a>
                  Goldenbayc
                </h6>
              </div>
            </div>
          </div>
          <div className="slider_item">
            <div>
              <div className="s_img">
                <img alt="" src={slide1} />
              </div>
              <div className="s_desc">
                <h3>
                  Golden Bayc <br />
                  Lorem Ipsum
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <h2>Development Manager</h2>
                <h6>
                  <a className="iconTwitter" href="javascriptt:void(0)">
                    <img src={tw} alt="twitter" />
                  </a>
                  Goldenbayc
                </h6>
              </div>
            </div>
          </div>
          <div className="slider_item">
            <div>
              <div className="s_img">
                <img alt="" src={slide2} />
              </div>
              <div className="s_desc">
                <h3>
                  Golden Bayc <br />
                  Lorem Ipsum
                </h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
                <h2>Development Manager</h2>
                <h6>
                  <a className="iconTwitter" href="javascriptt:void(0)">
                    <img src={tw} alt="twitter" />
                  </a>
                  Goldenbayc
                </h6>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default TeamSlider;
