import React, {useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import './HeaderStyle.scss'

const Header = (props) => {
    const [showSidebar, setShowSidebar] = useState(true)
    useEffect(() => {
        const body = document.body;
        const nav = document.querySelector(".header_style");
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;

        window.addEventListener('scroll', () => {
            const currrentScroll = window.pageYOffset;

            if(currrentScroll <= 0){
                body.classList.remove(scrollUp)
                return
            }
            if(currrentScroll > lastScroll && !body.classList.contains(scrollDown)){
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown)
            }else if(
                currrentScroll < lastScroll && body.classList.contains(scrollDown)){
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp)
            }
            lastScroll = currrentScroll
        })

        // nav links

        // document.querySelectorAll('.navLink').forEach(function(el){
        //     el.addEventListener('click', function(e){
        //         e.preventDefault();
        //         // console.log('Links')
        //         const id = this.getAttribute('href');
        //         console.log(id);
        //         document.querySelector(id).scrollIntoView({
        //             behavior:'smooth',
        //         })
        //     })
        // })

        document.querySelector('.nav_links').addEventListener('click', function(e){
            e.preventDefault();
            if(e.target.classList.contains('navLink')){
                const id = e.target.getAttribute('href');
                console.log(id);
                setShowSidebar({showSidebar:false});
                document.querySelector(id).scrollIntoView({
                    behavior:'smooth',
                })
            }
            
        })
      
    }, [])
    
  return (
    <>
    <div className='container-full header_style'>
        <div className='container-inner navBar'>
            <div className='row-inner'>
                <div className='col-inner col_brand'>
                    <a href=''className='brand'>Elite</a>
                </div>  
                <div className='col-inner col_actions'>
                    <ul>
                        <li><a href='#' className='icon_openSea' target="_blank"></a></li>
                        <li><a href='#' className='icon_twitter' target="_blank"></a></li>
                        <li><a href='#' className='icon_instagram' target="_blank"></a></li>
                        <li><a href='#' className='icon_discord' target="_blank"></a></li>
                    </ul>
                    <button className={`btn_menu ${showSidebar ? '' : 'btn_closeMenu'}`} onClick={()=> setShowSidebar(!showSidebar)} />
                </div>
            </div>
        
        </div>
    </div>
    <div className={`sidebar ${showSidebar ? 'hideSidebar' : ''}`}>
        <ul className='nav_links'>
            <li><a className='navLink' href='#secAbout'>About the Project</a></li>
            <li><a className='navLink' href='#secRoadmap'>Roadmap</a></li>
            <li><a className='navLink' href='#secStory'>Story</a></li>
            <li><a className='navLink' href='#perGsD'>Perks</a></li>            
            <li><a className='navLink' href='#secOurTeam'>Our Team</a></li>
            <li><a className='navLink' href='#secFAQ'>FAQ's</a></li>
        </ul>
    </div>
    </>
  )
}

export {Header}